import api from "@/api";
import { DefaultSetting } from "@/models/type";
import { Vue } from "vue-class-component";
import Label from "../home/components/Label";
import './index.scss'
import bg1 from './assets/bg_1.png'
export default class About extends Vue {

  public list: Array<SysPropertiesItemList> = []
  public teamList: Array<SysTeam> = []
  public institutionList: Array<SysInstitutionNew> = []
  public cooperationList: Array<SysCooperation> = []
  type = 1

  getData(): void {
    Promise.all([
      api.getSpaceIntroduce(),
      api.getTeamList(),
      api.getInstitutionList(),
      api.getCooperationList()
    ]).then(res => {
      this.list = res[0].data
      this.teamList = res[1].data
      this.institutionList = res[2].data
      this.cooperationList = res[3].data
    })
  }

  selectDom(): JSX.Element {
    return <div class="about-select">
      <div onClick={() => this.type = 1} class={this.type === 1 ? 'active': ''}>团队成员</div>
      <span />
      <div onClick={() => this.type = 2} class={this.type === 2 ? 'active': ''}>合作机构</div>
      {/* <span /> */}
      {/* <div onClick={() => this.type = 3} class={this.type === 3 ? 'active': ''}>友好连结</div> */}
    </div>
  }

  cellDom(): JSX.Element {
    switch (this.type) {
      case 1:
        return <div class="team">{
          this.teamList.map(item => <div class="team-list">
            <div class="team-left">
              <div/>
              <img src={item.imgPath} alt="" />
            </div>
            <div class="team-right">
              <div class="name">{item.cnName}（{item.enName}）</div>
              <div class="identity">{item.identity}</div>
              <div class="team-right-bottom">
                <div class="motto">{item.motto}</div>
                <div class="bottom">
                  <i class="el-icon-message"/>
                  <span>{item.email}</span>
                </div>
              </div>
            </div>
          </div>)
        }</div>
      case 2:
        return <div class="about-institution">
          {this.institutionList.map(item => (
            <div class="institution-cell">
              <div class="institution-cell-title">{item.type}</div>
              {item.sysInstitutions?.map(item => <div class="institution-cell-list">
                <div class="institution-cell-block">
                  <img src={item.icon} alt="" />
                  <div class="institution-cell-right">
                    <div>{item.title}</div>
                    <div>{item.introduce}</div>
                    <div class="pointer" onClick={() => window.open(item.url)}>{item.url}</div>
                  </div>
                </div>
              </div>)}
            </div>
          ))}
        </div>
      case 3:
        return <div class="about-cooperation">
          {this.cooperationList.map(item => <div>{ item.title }</div>)}
        </div>
      default:
        return <div/>
    }
  }

  showDom(): JSX.Element {
    return <div class="about-main" 
    style={{backgroundImage: 'url(img/about/bg.jpg)',backgroundSize: '100% 150%',backgroundRepeat: 'no-repeat'}}
    >
      <el-row>
        <el-col xs={12} sm={12} md={12} lg={12} xl={12}>
          <img src={bg1} alt="" />
        </el-col>
        <el-col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div class="about-main-right">
            <div>西九社區共融服務中心本著人可成才、家庭為基、社區為本、跨地共融、助人自助的理念，為內地新來港定居人士的低收入群體提供適切的服務，幫助內地新來港定居人士盡快融入社會，服務社會，建立和諧社區。希望透過中心的服務，協助新來港人士融入香港社會、為低收入家庭提供發展機會、關懷青少年健康成長及教育、提升婦女能力。</div>
            <div class="about-main-right-fly">
              <div>香港青少年文創空間</div>
              <div>搭建新興的以科技、文化、藝術、創意產品 結合而成的社會產業生態鏈</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="about-main-l2">
        <el-col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div class="about-main-left">
          香港青少年文創空間--道驛空間項目致力傳承和發展中華優秀文化藝術，道驛空間作為一個文化藝術工作者的驛站，希望透過知識的傳播和社會教化起到“溯本清源，直指人心”，有效服務青少年群體的作用；成為培養熱愛文化和藝術的青少年平台，引領大家由空間出發，察看祖國之大，灣區之無限可能，同時發掘屬於自己的一片新天地。
           </div>
        </el-col>
        <el-col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div class="about-main-right2">
            <img src={bg1} alt="" />
            <div class="about-main-right2-fly">
              <div>空間願景</div>
              {/* <div>That's what we want</div> */}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  }


  render(): JSX.Element {
    return <div class="about">
      <Label title={{ zh: '空間介紹', cn: 'INTRODUCE' }} />
      {this.showDom()}
      {/* {this.selectDom()} */}
      {/* {this.cellDom()} */}
    </div>
  }
  mounted(): void {
    this.getData()
  }
}

/**
 * @description 空间介绍
 * @author Jinx
 * @date 2021-08-20 09:51:39
 * @export
 * @interface SysPropertiesItemList
 * @extends {DefaultSetting}
 */
 export interface SysPropertiesItemList extends DefaultSetting {
  paramKey?: string // key
  paramValue?: string // key
  propertiesCode?: string // 属性代码
  remark?: string // 备注
  status?: number // 状态 0不启用 1启用
  title?: string
  type?: string // 类型
}


/**
 * @description 团队成员
 * @author Jinx
 * @date 2021-08-20 12:09:30
 * @export
 * @interface SysTeam
 * @extends {DefaultSetting}
 */
export interface SysTeam extends DefaultSetting {
  cnName?: string // 中文名
  email?: string // 邮箱
  enName?: string // 英文名
  identity?: string // 身份
  imgPath?: string // 图片路径
  motto?: string // 格言
}

/**
 * @description 合作机构
 * @author Jinx
 * @date 2021-08-20 14:09:18
 * @export
 * @interface SysInstitution
 * @extends {DefaultSetting}
 */
export interface SysInstitution extends DefaultSetting {
  introduce?: string // 简介
  icon?: string // 图标
  title?: string // 名称
  type?: string // 属性
  url?: string // 链接
}

export interface SysInstitutionNew {
  type: string,
  sysInstitutions: Array<SysInstitution>
}


/**
 * @description 友好连接
 * @author Jinx
 * @date 2021-08-20 14:47:33
 * @export
 * @interface SysCooperation
 * @extends {DefaultSetting}
 */
export interface SysCooperation	extends DefaultSetting {
  sort?: number // 排序
  title?: number // 名称
}
